import React from "react";
import TaskManager from "../TaskManager/TaskManager";

function App() {
  return (
    <main className="tl-main">
      <TaskManager />
    </main>
  );
}

export default App;
